import { DateTime, DateTimeUnit } from 'luxon';

export interface DateRange {
	begin: Date;
	end: Date;
}

export const today: DateRange = {
	begin: DateTime.now().startOf('day').toUTC().toJSDate(),
	end: DateTime.now().startOf('day').plus({ days: 1 }).toUTC().toJSDate(),
};

export const rangeUnit = (dr: DateRange): DateTimeUnit => {
	const interval = DateTime.fromJSDate(dr.end).diff(
		DateTime.fromJSDate(dr.begin),
		['days']
	);
	if (interval.days > 20)
		return 'month'; // arbitrary, we know every month has > 20 days
	else if (interval.days > 2)
		return 'week'; // arbitrary, we know every week has > 2 days
	else return 'day';
};
