import { LinkyButton } from './LinkyButton';
import { DateTime, DateTimeUnit } from 'luxon';
import styles from './DateRangePicker.module.css';
import { DateRange, rangeUnit, today } from '../models/DateRange';

const canMoveToRange = (dr: DateRange) =>
	DateTime.fromJSDate(dr.begin).diffNow('years').years > -1 &&
	DateTime.fromJSDate(dr.begin).diffNow().milliseconds < 1;

interface DateRangePickerProps {
	value: DateRange;
	onChange: (newValue: DateRange) => void;
}
export const DateRangePicker = ({ value, onChange }: DateRangePickerProps) => {
	const lxBegin = DateTime.fromJSDate(value.begin);
	const lxEnd = DateTime.fromJSDate(value.end);
	const unit = rangeUnit(value);
	const titleUnitName = unit[0].toLocaleUpperCase() + unit.substring(1);

	// slide the beginning back a day, and then go to the beginning of the selected unit
	const backwardBegin = lxBegin.minus({ days: 1 }).startOf(unit);
	const backwardRange: DateRange = {
		begin: backwardBegin.toUTC().toJSDate(),
		end: backwardBegin.endOf(unit).toUTC().toJSDate(),
	};
	const canGoBackward = canMoveToRange(backwardRange);

	// slide the end forward a day, and then go to the beginning of the selected unit
	const forwardBegin = lxEnd.plus({ days: 1 }).startOf(unit);
	const forwardRange: DateRange = {
		begin: forwardBegin.toUTC().toJSDate(),
		end: forwardBegin.endOf(unit).toUTC().toJSDate(),
	};
	const canGoForward = canMoveToRange(forwardRange);

	const changeInterval = (newInterval: DateTimeUnit) =>
		onChange({
			begin: lxBegin.startOf(newInterval).toJSDate(),
			end: lxBegin.endOf(newInterval).toJSDate(),
		});

	const moveBackward = () => onChange(backwardRange);
	const moveForward = () => onChange(forwardRange);
	const moveToToday = () => onChange(today);

	const moveToDate = (date: string) => {
		const lxDate = DateTime.fromISO(date);
		onChange({
			begin: lxDate.startOf(unit).toUTC().toJSDate(),
			end: lxDate.endOf(unit).toUTC().toJSDate(),
		});
	};

	return (
		<div className={styles.dateRangePicker}>
			<LinkyButton onClick={moveForward} disabled={!canGoForward} title={`Move forward a ${unit}`}>
				Next {titleUnitName}
			</LinkyButton>
			<input
				type="date"
				value={lxBegin.toLocal().toISODate()!}
				min={DateTime.now().minus({ years: 1 }).startOf('day').toISODate()!}
				max={DateTime.now().startOf('day').toISODate()!}
				onChange={e => moveToDate(e.target.valueAsDate?.toISOString()!.split('T').at(0)!)}
			/>
			<LinkyButton onClick={moveBackward} disabled={!canGoBackward} title={`Move back a ${unit}`}>
				Previous {titleUnitName}
			</LinkyButton>
			<div className={styles.text}>
				<LinkyButton active={unit === 'day'} onClick={() => changeInterval('day')}>
					Day
				</LinkyButton>
				<LinkyButton active={unit === 'week'} onClick={() => changeInterval('week')}>
					Week
				</LinkyButton>
				<LinkyButton active={unit === 'month'} onClick={() => changeInterval('month')}>
					Month
				</LinkyButton>
				<LinkyButton onClick={moveToToday}>Today</LinkyButton>
			</div>
		</div>
	);
};
