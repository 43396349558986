import { ButtonHTMLAttributes } from 'react';
import styles from './LinkyButton.module.css';

interface LinkyButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	active?: boolean;
}
export const LinkyButton = (props: LinkyButtonProps) => {
	const { active, ...buttonProps } = props;
	return (
		<button
			{...buttonProps}
			className={`${styles.linkyButton} ${buttonProps.className ?? ''} ${
				active ? styles.active : ''
			}`}
		/>
	);
};
