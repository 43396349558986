import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
	CategoryScale,
	LinearScale,
	Chart,
	PointElement,
	LineElement,
	TimeScale,
	BarElement,
	ArcElement,
	Tooltip,
	Legend
} from 'chart.js';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

Chart.register(
	CategoryScale,
	LinearScale,
	TimeScale,
	PointElement,
	LineElement,
	BarElement,
	ArcElement,
	Tooltip,
	Legend
);

Chart.defaults.color = '#414141';
Chart.defaults.interaction = {
	...Chart.defaults.interaction,
	mode: 'nearest',
	axis: 'x',
	intersect: false,
};
Chart.defaults.elements.point.hoverRadius = 10;

const queryClient = new QueryClient();

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
	</React.StrictMode>
);
