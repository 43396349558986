export const WidgetHolder = (props: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		style={{
			backgroundColor: 'rgba(256, 256, 256, 0.9)',
			borderRadius: '15px',
			padding: '0.75rem',
		}}
		{...props}
	></div>
);
