import { useGenTotals } from '../api/HistoricalApi';
import { Plant } from '../models/Plant';
import { CountWrapper } from './CountWrapper'
import styles from './TotalGenTable.module.css';

export const TotalGenChart = (props: { plant: Plant }) => {
	const { data: genTotals } = useGenTotals(props.plant.abbreviation);
	return (

		<div className={styles.factHolder}>
			<div className={styles.wrapper}>
				<div className={styles.flexHeader}>Total Generation {'(' + props.plant.powerUnits + 'h)'}</div>
				<div className={styles.flexComponent}>
					<CountWrapper label='Today' startingValue={0} finalValue={Math.round(genTotals?.dayGen ?? 0)} color={props.plant.primaryColor} decimals={0} />
					<CountWrapper label='This Month' startingValue={0} finalValue={Math.round(genTotals?.monthGen ?? 0)} color={props.plant.primaryColor} decimals={0} />
					<CountWrapper label='This Year' startingValue={0} finalValue={Math.round(genTotals?.yearGen ?? 0)} color={props.plant.primaryColor} decimals={0} />
				</div>
			</div>
		</div>
	);
};
