import Page from './Page';
import { usePlants } from './api/PlantApi';
import styles from './App.module.css';

function App() {
	const { isLoading, data: plants } = usePlants();
	const plantRoute = window.location.pathname.replace('/', '');
	const plant = plants?.find(p => p.name.toLowerCase().includes(plantRoute === '' ? 'wing' : plantRoute));

	if (plant !== undefined) {
		document.documentElement.style.setProperty('--primary-color', plant?.primaryColor);
		document.documentElement.style.setProperty('--secondary-color', plant?.secondaryColor);
	}

	if (isLoading) return <></>;
	if (!isLoading && !plant) return <p>{`Couldn't find plant with name ${plantRoute}`}</p>;

	return (
		<div className={styles.App} style={{
			backgroundImage: `linear-gradient(180deg, ${plant?.primaryColor} 5%, transparent 50%, ${plant?.primaryColor} 95%), url('${plant?.bgImageLink}')`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			fontFamily: `'Roboto', sans-serif`,
			fontSize: '17px',
			color: plant?.secondaryColor
		}}>
			<Page plant={plant!} />
		</div>
	);
}

export default App;