// types
import { WeatherConditions } from '../../models/WeatherConditions';

// css
import styles from './CloudCoverGauge.module.css'

export const CloudCoverGauge = (props: {
    conditions: WeatherConditions | undefined
}) => {
    return (
        props.conditions ?
            <div className={styles.gauge}>
                <span className={styles.weather}>
                    <img src={props.conditions.imageUrl} alt={'weather icon'} />
                    <span className={styles.temperature}>{`${Math.round(props.conditions.temperature)}°`}</span>
                </span>
                <span className={styles.mediumText}>{props.conditions.text}</span>
                <span>{`${props.conditions.cloudCover}% cover`}</span>
            </div>
            : <div className={styles.gauge}></div>
    );
};