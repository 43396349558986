import CountUp from 'react-countup'
import styles from './CountWrapper.module.css'


interface CountWrapperProps {
    label: string,
    startingValue: number,
    finalValue: number,
    color: string,
    decimals: number,
};


export const CountWrapper = ({label, startingValue, finalValue, color, decimals} : CountWrapperProps) => {
    const textStyle: React.CSSProperties = {
        color: color,
        fontSize: 60
    }
    
    return (
        <div className={styles.fact}>
            <CountUp style={textStyle} start={startingValue} end={finalValue} duration={2} decimals={decimals} />
            <div className={styles.factKey}>{label}</div>
        </div>
    );
};