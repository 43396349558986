import { useQuery } from '@tanstack/react-query';
import { apiClient } from './apiUtils';
import { SolarGeneration } from '../models/SolarGeneration';
import { Duration } from 'luxon';
import { GenerationTotals } from '../models/GenerationTotals';

const queryKey = 'Historical';

export const useHistoricalData = (begin: Date, end: Date, plantName: string) =>
	useQuery({
		queryKey: [queryKey, 'Gen', begin, end, plantName],
		staleTime: Duration.fromObject({ minutes: 30 }).as('milliseconds'),
		queryFn: async () =>
			(
				await apiClient.get<SolarGeneration[]>('/Historical/Gen', {
					params: { begin, end, plantName },
				})
			).data,
	});

export const useGenTotals = (plantName: string) =>
	useQuery({
		queryKey: ['TotalGen', plantName ],
		staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
		queryFn: async () =>
			(
				await apiClient.get<GenerationTotals>('Historical/TotalGen', {
					params: { plantName }
				})
			).data,
	});
