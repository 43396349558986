import { useQuery } from '@tanstack/react-query';
import { apiClient } from './apiUtils';
import { WeatherConditions } from '../models/WeatherConditions';
import { Duration } from 'luxon';

export const useCurrentWeather = (plantName: string) =>
	useQuery({
		queryKey: ['CurrentWeather', plantName],
		staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
		queryFn: async () =>
			(
				await apiClient.get<WeatherConditions>('/WeatherForecast/CurrentWeather', {
					params: { plantName },
				})
			).data,
	});

export const useWeatherHistory = (begin: Date, end: Date, plantName: string) =>
	useQuery({
		queryKey: ['WeatherForecast/HistoricalWeather', begin, end, plantName],
		staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
		queryFn: async () =>
			(
				await apiClient.get<WeatherConditions[]>('/WeatherForecast/HistoricalWeather', {
					params: { begin, end, plantName },
				})
			).data,
	});
