import { useState } from 'react';
import { DateRangePicker } from './DateRangePicker';
import { Bar, Line } from 'react-chartjs-2';
import { useHistoricalData } from '../api/HistoricalApi';
import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';
import styles from './GenChart.module.css';
import { useWeatherHistory } from '../api/WeatherForecastApi';
import { rangeUnit, today } from '../models/DateRange';
import { Plant } from '../models/Plant';

export const GenChart = (props: { plant: Plant }) => {
	const [range, setRange] = useState(today);
	const { data: historicalData } = useHistoricalData(range.begin, range.end, props.plant.abbreviation);
	const { data: weather } = useWeatherHistory(range.begin, range.end, props.plant.abbreviation);
	const unit = rangeUnit(range);
	const showBars = unit === 'month' || unit === 'week';

	const data = {
		datasets: [
			{
				label: props.plant.name + ' Generation',
				backgroundColor: props.plant.primaryColor,
				borderColor: props.plant.primaryColor,
				data: historicalData?.map(hd => ({
					x: DateTime.fromJSDate(new Date(hd.utcTime + 'Z')),
					y: showBars ? hd.gen : Math.min(hd.gen, props.plant.maxValue),
				})),
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		elements: {
			point: {
				radius: 0,
			},
		},
		scales: {
			x: {
				type: 'time',
				time: {
					unit: showBars ? 'day' : 'hour',
				},
			},
			y: {
				type: 'linear',
				min: props.plant.minValue,
				// add 10 to the max value of the plant so that the line does not go off the top of the chart
				max: showBars ? undefined : (props.plant.maxValue + 10), // y-max is max cap of the panels, but for monthly/weekly mwh, this is much greater than the plant max value
				ticks: {
					stepSize: 10, // configures the scale of the graph on the y-axis to be intervals of 10
				},
			},
		},
		plugins: {
			tooltip: {
				mode: 'index' as const,
				callbacks: {
					label: (context: any) =>
						`${context.dataset.label}: ${context.parsed.y} ${showBars ? (' ' + props.plant.powerUnits + 'h') : (' ' + props.plant.powerUnits)}`,
					title: (item: any) =>
						!showBars
							? // this might look duplicated, but its to keep us form adding 12 hours to the tooltip for the hourly view
							DateTime.fromMillis(item[0].parsed.x).toLocaleString(DateTime.DATETIME_SHORT)
							: // get this to midday so we don't accidently have wrong day because midnight is right on the day-change
							DateTime.fromMillis(item[0].parsed.x)
								.plus({ hour: 12 })
								.toLocaleString(DateTime.DATE_FULL),
					footer: (item: any) => {
						// grab the weather with the same date as this x coord
						const w = weather?.find(w => new Date(w.utcTime + 'Z').getTime() === item[0].parsed.x);

						let ret = '';
						if (w?.temperature !== undefined) ret += `Temperature: ${w.temperature.toFixed(1)}°F\n`;
						if (w?.cloudCover !== undefined) ret += `Cloud Cover: ${w.cloudCover.toFixed(1)}%`;
						return ret;
					},
				},
			},
		},
	};

	return (
		<>
			<DateRangePicker value={range} onChange={val => setRange(val)} />
			<div className={styles.chartContainer}>
				{showBars ? (
					<Bar data={data} options={options as any} />
				) : (
					<Line data={data} options={options as any} />
				)}
			</div>
		</>
	);
};
