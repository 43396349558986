// types
import { Plant } from '../models/Plant';

// components
import { CountWrapper } from './CountWrapper'

// api
import { useOutput } from '../api/OutputApi';

// css
import styles from './FactHolder.module.css'


export const FactHolder = (props: { plant: Plant }) => {
    const { data: output } = useOutput(props.plant.abbreviation);
    
    const powerPerPanel: number = ((output ?? 0 ) / (props.plant.solarPanels)) * (props.plant.powerUnits === "MW" ? 1000000 : 1000); //calculates the power output per cell
                                                                              //the output recieved from the api call could be undefined. if it is use 0
                                                                              // the ternary operator decides the conversion factor to watts
    
    const description: string = props.plant.powerUnits === "MW" ? "Homes Powered" : "Water Heaters Powered";

    return <div className={styles.factsHolder}>
        <CountWrapper label='Solar Panels' startingValue={0} finalValue={props.plant.solarPanels} color={props.plant.primaryColor} decimals={0} />
        <CountWrapper label={description} startingValue={0} finalValue={props.plant.homesSupplied} color={props.plant.primaryColor} decimals={0} />
        <CountWrapper label={'Watts Per Panel'} startingValue={0} finalValue={powerPerPanel} color={props.plant.primaryColor} decimals={1} />
    </div>; 
}