import styles from './Page.module.css';
import { GenChart } from './components/GenChart';
import { TotalGenChart } from './components/TotalGenTable';
import { WidgetHolder } from './components/WidgetHolder';
import { GaugeCluster } from './components/gauges/GaugeCluster';
import { FactHolder } from './components/FactHolder';
import { Plant } from './models/Plant';

function Page(props: { plant: Plant }) {

    return (
        <div>
            <header className={styles.header}>
                <img className={styles.logo} src={props.plant.logoImageLink} alt="main logo" />
                <span className={styles.title}>{props.plant.name}</span>
            </header>
            <main className={styles.main}>
                <WidgetHolder className={styles.chartContainer}>
                    <GenChart plant={props.plant} />
                </WidgetHolder>
                <WidgetHolder className={styles.tableContainer}>
                    <TotalGenChart plant={props.plant} />
                </WidgetHolder>
                <WidgetHolder>
                    <GaugeCluster plant={props.plant} />
                </WidgetHolder>
                <WidgetHolder>
                    {props.plant.description.replace(/\\n/g, '\n').split('\n').map((x, index) => <p key={index}>{x}</p>)}
                </WidgetHolder>
                <WidgetHolder>
                    <FactHolder plant={props.plant} />
                </WidgetHolder>
            </main>
            <footer className={styles.footer}>
                This information is provided AS IS and without warranties. It is provided for information
                purposes only and may not be accurate or complete. Cloud coverage & temperature details
                provided by{' '}
                <a href="https://www.weatherapi.com" title="Weather API">
                    WeatherAPI.com
                </a>
            </footer>
        </div>
    );
}

export default Page;