import styles from './GaugeCluster.module.css';
import { CloudCoverGauge } from './CloudCoverGauge';
import { useOutput } from '../../api/OutputApi';
import {
	useCurrentWeather
} from '../../api/WeatherForecastApi';
import ReactSpeedometer from 'react-d3-speedometer';
import { Plant } from '../../models/Plant';

export const GaugeCluster = (props: { plant: Plant }) => {
	//gets data from the api and passes it to individual gauges
	const { data: output } = useOutput(props.plant.abbreviation);
	const { data: weatherConditions } = useCurrentWeather(props.plant.abbreviation);

	// we occcasionally get data that is .3, .4, .2, etc.. above our maximum. for the speedometer we need to enforce that max to make the animation work
	// I tried setting the max value to be higher like we do in the graph but the numbers for each segment got ugly instead of just being mulitples of 20
	return (
		<div className={styles.gaugeCluster}>
			<CloudCoverGauge conditions={weatherConditions} />
			{ /* eslint-disable-next-line no-template-curly-in-string -- ReactSpeedometer requests this format*/}
			<ReactSpeedometer value={Math.min(output ?? 0, props.plant.maxValue)} maxValue={props.plant.maxValue} startColor='#ddd' endColor={props.plant.primaryColor} needleColor="#000000" currentValueText={'${value}' + props.plant.powerUnits + ' Current'} height={200} />
		</div>
	);
};
