import { useQuery } from '@tanstack/react-query';
import { apiClient } from './apiUtils';
import { Duration } from 'luxon';

export const useOutput = (plantName: string) =>
	useQuery({
		queryKey: ['Output', plantName],
		staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
		queryFn: async () => (await apiClient.get<number>('/Output', {
			params: { plantName }
		}
		)).data,
	});
